<template>
  <div>
    <BackFa v-if="isRtl" :text="$t('back')" />
    <div class="m-5 bg-white">
      <div class="border border-gray-400">
        <div
          :class="{ 'flex justify-between': isLtr, 'text-right': isRtl }"
          class="p-4"
        >
          <div>
            <span
              :class="{ 'text-rose': isLtr, 'text-gray-400': isRtl }"
              class="font-bold text-lg"
            >
              {{ $t('title_repors') }}
            </span>
            <span class="text-rose"> {{ $t('title_repors_dentis') }}</span>
          </div>
          <BackFa v-if="isLtr" :text="$t('back')" />
        </div>

        <BaseTable
          class="text-right mb-6"
          header-class="bg-gray-50 text-gray-400 text-base"
          :headers="headers"
          :items="items"
          theme-type="even"
        >
          <template #item:docter="{ item }">
            <div class="flex items-center">
              <div
                :class="{ 'ml-2': isRtl, 'mr-2': isLtr }"
                class="bg-purple-400 h-10 w-10 rounded-lg"
              ></div>
              <div class="font-extralight">
                <h3 class="text-base">{{ item.docter }}</h3>
                <h3 class="text-xs font-thin text-gray-400">
                  {{ item.specialty }}
                </h3>
              </div>
            </div>
          </template>
          <template #item:document="{ item }">
            <img width="32" class="mx-auto" :src="item.document" alt="" />
          </template>
          <template #item:allergy="{ item }">
            <span
              role="button"
              @click="goto(item.to)"
              class="bg-blue-900 text-white px-6 py-1 rounded-2xl shadow-md"
              >{{ item.allergy }}</span
            >
          </template>
          <template #item:image>
            <i class="text-2xl text-gray-400 fal fa-file-download"></i>
          </template>
        </BaseTable>
      </div>
    </div>
  </div>
</template>
<script>
import BaseTable from '@/components/BaseTable'
import BackFa from '../components/back/BackFa.vue'

export default {
  name: 'ReportsDentis',
  components: { BaseTable, BackFa },
  computed: {
    headers() {
      return [
        {
          key: 'docter',
          text: `${this.$t('doctor_reports')}/${this.$t('specialty_reports')}`,
        },
        {
          key: 'date',
          text: this.$t('date_reports'),
        },
        {
          key: 'subject',
          text: this.$t('subject_reports2'),
        },
        {
          key: 'allergy',
          text: this.$t('allergy_reports'),
        },
        {
          key: 'image',
          text: this.$t('image_reports_dentis'),
          align: 'center',
        },
      ]
    },
    items() {
      return [
        {
          date: this.convertDate('2021-1-1'),
          specialty: this.$t('specialty_reports_dentis'),
          docter: this.$t('name3_main_page_list'),
          subject: this.$t('subject_reports_dentis'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports'),
        },
        {
          date: this.convertDate('2020-1-5'),
          specialty: this.$t('specialty_reports_dentis'),
          docter: this.$t('name3_main_page_list'),
          subject: this.$t('subject_reports_dentis2'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports_dentis'),
          to: { name: 'SlideTeeth' },
        },
        //           "../assets/images/theme/edit-icon.png"
        // "../assets/images/theme/text-icon.png"
        // "../assets/images/theme/comment-icon.png"
        // "../assets/images/theme/text-left-icon.png"
        // "../assets/images/theme/edit-pen-icon.png"
        // "../assets/images/theme/circle-icon.png"
        // "../assets/images/theme/text-icon.png"
      ]
    },
  },
  methods: {
    goto(to) {
      !!to && this.$router.push(to)
    },
  },
}
</script>

<style></style>
